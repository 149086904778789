import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import api from '#api/Market'
import basepath, { navigate } from '#components/basepath'
import NavigationBar from '#root/src/components/NavigationBar'
import Image from 'next/image'

import Cookies from 'js-cookie'
import dynamic from 'next/dynamic'

import NavSidebar from './NavSidebar'
import { useTranslation } from 'next-i18next'
import SignInOverlay from '#components/SignInOverlay'
import SignUpOverlay from '#components/SignUpOverlay'
import { toast } from 'react-toastify'

import { loaderProp } from '#root/utils/image-utils'
import { useCartCount } from '../global-storage/cart'
import { useSignInOverlay, useSignUpOverlay } from '../global-storage/overlays'
import MobileNav from '#components/MobileNav'
import { useIsLoggedIn } from '../global-storage/auth'
import ApiAuth from '#api/Auth'
import { useSession, signOut } from 'next-auth/react'
import {isValidJson} from '#root/utils/boolean-utils'
import { fetchLocationData } from '#root/utils/locationUtils'
import { useGoogleOneTapLogin } from '@react-oauth/google'
import {searchPriorityGenerator} from '#root/utils/search-priority-utils'

const SitePopUpBox = dynamic(() => import('#root/src/components/SitePopUpBox'))

export default function Headers({ locale, productName, isMobile, urlTranslate, yourOnlineDoctorPharmacyIsH2}) {
  const { t } = useTranslation('header')
  const router = useRouter()
  const { data: session } = useSession()


  // global state ========================
  const signInOverlay = useSignInOverlay()
  const signUpOverlay = useSignUpOverlay()
  const { isLoggedIn, setIsLoggedIn } = useIsLoggedIn()
  const userToken = Cookies.get('userToken')
  const { data: cartCount = 0 } = useCartCount(isLoggedIn ? userToken : null);
  
  const [keyword, setKeyword] = useState('')
  // const [pageLanguage,setPageLanguage]=useState('en') TODO: remove if not needed

  const [settingPopUp, setSettingPopUp] = useState(false)
  const [deliveryText, setDeliveryText] = useState({})

  // show name and location feature 
  const fetchLocation = async () => {
    const data = await fetchLocationData()
    // const { city, postal } = data;
    if (!data) {
      console.error('error fetch ip')
    } else {
      deliveryText.location = `${data?.city} ${data?.postal}`
      setDeliveryText({location: `${data?.city} ${data?.postal}`})
    }
  };

  const fetchUserInfo = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (userDetails) {
        setDeliveryText({
          ...deliveryText,
          name: userDetails?.user?.name
        })
        const addressB2c = await api.getAddress({ userToken, isList: true });
        if (addressB2c && addressB2c.city && addressB2c.postcode) {
          setDeliveryText({
            ...deliveryText,
            location: `${addressB2c?.city} ${addressB2c?.postcode}`
          })
        } else {
          await fetchLocation();
        }
      } else {
        await fetchLocation();
      }
    } catch (error) {
      console.error('Error fetching user address', error);
      await fetchLocation();
    }
  };
  useEffect(async () => {
    const userDetails = localStorage.getItem('userDetails');
    if (userDetails) {
      await fetchUserInfo();
    } else {
      await fetchLocation();
    }
  }, [isLoggedIn]);

  //handle res from google sign in button
  useEffect(async () => {
    if (session && !isLoggedIn) {
      handleGoogleSignIn(session.id_token)
    }
  }, [session])
  const handleGoogleSignIn = async(token) => {
    try {
      const res = await ApiAuth.googleLogin(token)
      //store in local storage
      const user = await ApiAuth.verifyToken(res.json.token, res.token) //note: first token is doc auth token, second token in b2c platform token
      localStorage.setItem('userDetails', JSON.stringify(user))
      //update states
      setIsLoggedIn(true)
      window.location.reload();

    } catch (error) {
      isValidJson(error.message)? JSON.parse(error.message)?.detail:error.message
      //if user not founnd
      if(JSON.parse(error?.message)?.detail == 'User not found'){
        toast.warning(t("userNotFoundError"))
        signUpOverlay.setState('active')
      }
      !JSON.parse(error.message).message==='Error From DOC Auth: Bad Request'&& toast.warning( "An error occured. Please login again.", {
        position:
        isMobile && isMobile.windowSize < 767
        ? toast.POSITION.TOP_RIGHT
        : toast.POSITION.BOTTOM_RIGHT
      })
      signOut({redirect: false}) //clear session if logout failed
    }
  }
  //google one-tap
  useGoogleOneTapLogin({
    onSuccess: (res) => handleGoogleSignIn(res.credential),
    onError: () => {
      console.log(error)
      toast.warning(t('errorOccured2'), {
        position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
      })
    },
    disabled: isLoggedIn
  })

  // console.log('keywordkeyword', keyword)
  // const [cookielanguage, setCookieLanguage] = useState('');

  // const toggle = (language)=> {
  //   Cookies.set('pageLanguage', language)
  //   changeLang(language)
  // }

  // function changeLang(locale) {
  //   // Cookies.set('language', locale)
  //   // setCookieLanguage(locale)
  //   router.push({
  //     route: router.route,
  //     query: router.query
  //   }, router.asPath, { locale });
  // }
  // useEffect( () => {
  //   if(Cookies.get('pageLanguage')){
  //     setPageLanguage(Cookies.get('pageLanguage'))
  //     if(Cookies.get('pageLanguage')!==locale){
  //       changeLang(Cookies.get('pageLanguage'))
  //     }
  //   }
  // }, [])
  useEffect(async () => {
    if (localStorage.getItem('userDetails')) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [])

  // useEffect(() => {
  //   const languageInCookie = Cookies.get('language');
  //   if (!languageInCookie) {
  //     Cookies.set('language', 'en')
  //   } else {
  //     Cookies.set('language', languageInCookie)
  //     // changeLang(languageInCookie)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (cookielanguage !== '') {
  //     Cookies.set('language', cookielanguage)
  //   }
  // }, [cookielanguage])

  // useEffect(async () => {

  //   await api.checkAuth()
  // }, [])

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction()
    }

    function scrollFunction() {
      if (document.body.scrollTop > 90 || document.documentElement.scrollTop > 90) {
        document.getElementById('header').classList.add('shrink')
      } else {
        document.getElementById('header').classList.remove('shrink')
      }
    }
  }, [])

  useEffect(() => {
    setKeyword(router.query.search ?? '')
  }, [])

  // const logout = () => {
  //   try {
  //     api.logout()
  //   } catch (ex) {
  //   } finally {
  //     navigate.replace(`/auth`)
  //   }
  // }

  // Event.on("failed_auth", () => {
  //     logout();
  //     //navigate.replace("/");
  // });

  const searchHandler = (event) => {
    setKeyword(event.target.value ?? '')
  }

  const onSubmit = (event) => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      event.preventDefault()

      let searchPriority = searchPriorityGenerator({
        route: router?.pathname
      })

      let queryParams = `keyword=${keyword}&search_priority=${encodeURIComponent(searchPriority)}`;

      navigate.push(`${locale === 'en' ? '' : '/' + locale}/search?${queryParams}`);
    }
  }

  const applySiteSetting = (siteSetting) => {
    if (siteSetting.countrySetting !== null) {
      let redirectSiteUrl = null;
  
      if (!Array.isArray(urlTranslate) || urlTranslate.length === 0) {
        console.error('urlTranslate is either not defined or empty');
        return
      }
  
      if (siteSetting.countrySetting == 'MY') {
        redirectSiteUrl = urlTranslate.find((x) => x.hreflang == 'en')?.href;
      }
  
      if (siteSetting.countrySetting == 'ID') {
        redirectSiteUrl = urlTranslate.find((x) => x.hreflang == 'id-ID')?.href;
      }
  
      if (siteSetting.countrySetting == 'SG') {
        redirectSiteUrl = urlTranslate.find((x) => x.hreflang == 'en-SG')?.href;
      }
  
      if (!redirectSiteUrl) {
        console.error(`No redirect URL found for country setting: ${siteSetting.countrySetting}`);
        return
      }
  
      localStorage.setItem('selectedCountry', siteSetting.countrySetting.toLowerCase());
  
      window.location.replace(
        process.env.NEXT_PUBLIC_ENV === 'local'
          ? `http://${redirectSiteUrl}`
          : `https://${redirectSiteUrl}`
      );
    }
  };

  return (
    <>
      <header
        id="header"
        className="MarketHeader br-b-gray-1 bg-white pos-fix fullwidth sr t-05-ease"
      >
        {/* Sign In Overlay Component */}
        {signInOverlay?.isActive() && <SignInOverlay isMobile={isMobile} />}
        {/* Sign Up Overlay Component */}
        {signUpOverlay.isActive() && <SignUpOverlay isMobile={isMobile} />}
        <div id="nav-1" className="nav-1 bg-blue-1 pad slide-disable t-05-ease">
          <div className="flex-box fullwidth fullheight wrap-1 centralized ai-c jc-sb">
            {yourOnlineDoctorPharmacyIsH2 ? (
              <h2 className="f-g0-s1 f-g1-s0-767">{t('yourOnlineDoctorPharmacy')}</h2>
            ) : (
              <h1 className="f-g0-s1 f-g1-s0-767">{t('yourOnlineDoctorPharmacy')}</h1>
            )}

            <div className="f-g1-s1 ta-c r-767" style={{ display: 'none' }}>
              {t('downloadAppButton')}
              <div className="button" onClick={api.redirectToAppStore} target="_blank">
                {t('downloadNow')}
              </div>
            </div>
            {/* <div className="r-920 f-g0-s1 ta-r">
              { pageLanguage=='en'&&
                <button style={{ backgroundColor: 'transparent', color:'white', boxShadow:'none',border:'none' ,height:'min-content',fontSize:'1em',fontWeight:'500' }} onClick={() => toggle('id')}>
                  ID
                </button>
              }
              { pageLanguage=='id'&&
                <button style={{ backgroundColor: 'transparent', color:'white', boxShadow:'none',border:'none' ,height:'min-content',fontSize:'1em',fontWeight:'500' }} onClick={() => toggle('en')}>
                    EN
                </button>
              } 
              <a  style={{ paddingLeft: '2em'}}href="https://www.doctoroncall.com/marketplace/" target="_blank">
              {t('buyingBulk')}
              </a>
            </div>*/}
            <button
              className="country f-g0-s1 flex-box ai-c gap-05 image-button"
              onClick={() => setSettingPopUp(true)}
            >
              <div>{locale == 'en' ? 'INT' : locale}</div>
              <div>
                <img className="flag" src={'/images/flag-' + locale + '.svg'} alt={locale === 'sg' ? "singapore flag" : locale === 'id' ? "indonesia flag" : ""} />
              </div>
            </button>
          </div>
        </div>

        <div className="pos-rel pad">
          <div className="burger-wrapper pos-abs flex-box fullheight ai-c">
            <div
              id="burger"
              className="burger toggle"
              onClick={() => document.body.classList.toggle('slide')}
            >
              <span className="burger-line"></span>
              <span className="burger-line"></span>
              <span className="burger-line"></span>
            </div>
          </div>
          <div className="nav-2 flex-box ai-c fullwidth wrap-1 slide-disable t-05-ease pt-1 pb-1 gap-2">
            <div id="logo" className="logo t-05-ease f-g0-s0">
              <a href={router.pathname == '/' ? '#' : `${locale === 'en' ? '/' : '/' + locale}`}>
                <Image
                  src={basepath('/images/doc-logo.png')}
                  alt={'doc-logo'}
                  width={84}
                  height={38}
                  className="fullwidth"
                  loader={loaderProp}
                />
                <img
                  className="pharma fullwidth"
                  style={{ marginTop: '4.5%' }}
                  src="/images/doc-pharma.png"
                  alt="Online Pharmacy"
                />
                <img
                  className="shopping-cart fullwidth"
                  style={{ marginTop: '4.5%' }}
                  src="/images/doc-shoppingcart.png"
                  alt="Shopping Cart"
                />
                <img
                  className="health-services fullwidth"
                  style={{ marginTop: '4.5%' }}
                  src="/images/doc-health-services.png"
                  alt="Shopping Cart"
                />
              </a>
            </div>
            <div className="search-box">
              <input
                id="searchInput"
                autoComplete="off"
                className="search-bar fullwidth bg-gray-1"
                placeholder={t('searchDoc')}
                onChange={searchHandler}
                onKeyUp={onSubmit}
              />
            </div>
            <div className="main-nav r-767">
              <div className="flex-box gap-1">
                <div
                  href="#"
                  className="ta-c f-g1-s1 flex-box fd-c ai-c gap-025 temp-dn"
                  /*style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}*/
                >
                  <svg
                    className="header-login"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                  >
                    <g transform="translate(-1121 -191)">
                      <rect
                        className="class-a"
                        width="25"
                        height="25"
                        transform="translate(1121 191)"
                      ></rect>
                      <g transform="translate(1123 193)">
                        <path
                          className="class-b"
                          d="M10.988,13.129A5.363,5.363,0,0,1,5.852,7.564,5.363,5.363,0,0,1,10.988,2a5.363,5.363,0,0,1,5.136,5.564A5.363,5.363,0,0,1,10.988,13.129Zm0,0q7.7,0,8.988,6.848H2Q3.284,13.129,10.988,13.129Z"
                          transform="translate(0 0)"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <span>{t('account')}</span>
                </div>
                <div
                  className="ta-c f-g1-s1 flex-box fd-c ai-c gap-025"
                  onClick={() => {
                    if (isLoggedIn) {
                      navigate.push(`${locale === 'en' ? '' : '/' + locale}/profile`)
                    } else {
                      signInOverlay.setState('active')
                    }
                  }}
                >
                  <div className="pos-rel flex-box ai-c">
                    <svg
                      className="header-login"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                    >
                      <g transform="translate(-1121 -191)">
                        <rect
                          className="class-a"
                          width="25"
                          height="25"
                          transform="translate(1121 191)"
                        ></rect>
                        <g transform="translate(1123 193)">
                          <path
                            className="class-b"
                            d="M10.988,13.129A5.363,5.363,0,0,1,5.852,7.564,5.363,5.363,0,0,1,10.988,2a5.363,5.363,0,0,1,5.136,5.564A5.363,5.363,0,0,1,10.988,13.129Zm0,0q7.7,0,8.988,6.848H2Q3.284,13.129,10.988,13.129Z"
                            transform="translate(0 0)"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    {/* <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 50 50"
                      style={{ enableBackground: 'new 0 0 50 50' }}
                      xmlSpace="preserve"
                      width="25"
                      height="25"
                    >
                      <style type="text/css">
                        {`.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}`}
                      </style>
                      <g>
                        <path
                          id="Rectangle_103_"
                          className="st0"
                          d="M19.83,31.77l-6.29,6.29c-0.63,0.63-1.71,0.18-1.71-0.71v-5.59h-8c-1.1,0-2-0.9-2-2v-26 c0-1.1,0.9-2,2-2h30c1.1,0,2,0.9,2,2v26c0,1.1-0.9,2-2,2H19.83z"
                        />
                        <path
                          id="Path_143_"
                          className="st0"
                          d="M23.83,35.77v4c0,1.12,0.9,2,2,2h10l5.29,5.7c0.63,0.64,1.71,0.19,1.71-0.72v-4.98h3 c1.1,0,2-0.88,2-2V22.8c0-1.12-0.9-2.03-2-2.03h-6"
                        />
                        <path
                          className="st0"
                          d="M18.83,19.77c0,0,0-2,1-3c2-2,3-3,3-5s-1-4-4-4s-4,2-4,4"
                        />
                        <line className="st0" x1="18.83" y1="23.77" x2="18.83" y2="24.77" />
                      </g>
                    </svg> */}
                  </div>
                  <span>{isLoggedIn ? t('profile') : t('signIn')}</span>
                </div>
                <div
                  onClick={() => {
                    if ((process.env.NEXT_PUBLIC_ENV == 'prod' || process.env.NEXT_PUBLIC_ENV == 'uat') && Gleap?.getInstance()) {
                      Gleap.open()
                    } else {
                      window.open(
                        `https://api.whatsapp.com/send?phone=60179722669&text=%5BB2C%5D Please help me regarding: ${window.location.href}`,
                        '_blank'
                      )
                    }
                  }}
                  className="ta-c f-g1-s1 flex-box fd-c ai-c gap-025"
                  /*
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '15px'
                  }}
                  */
                >
                  <div className="pos-rel flex-box ai-c">
                    <svg
                      alt="Need help"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 50 50"
                      style={{ enableBackground: 'new 0 0 50 50' }}
                      xmlSpace="preserve"
                      width="25"
                      height="25"
                    >
                      <style type="text/css">
                        {`.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}`}
                      </style>
                      <g>
                        <path
                          id="Rectangle_103_"
                          className="st0"
                          d="M19.83,31.77l-6.29,6.29c-0.63,0.63-1.71,0.18-1.71-0.71v-5.59h-8c-1.1,0-2-0.9-2-2v-26 c0-1.1,0.9-2,2-2h30c1.1,0,2,0.9,2,2v26c0,1.1-0.9,2-2,2H19.83z"
                        />
                        <path
                          id="Path_143_"
                          className="st0"
                          d="M23.83,35.77v4c0,1.12,0.9,2,2,2h10l5.29,5.7c0.63,0.64,1.71,0.19,1.71-0.72v-4.98h3 c1.1,0,2-0.88,2-2V22.8c0-1.12-0.9-2.03-2-2.03h-6"
                        />
                        <path
                          className="st0"
                          d="M18.83,19.77c0,0,0-2,1-3c2-2,3-3,3-5s-1-4-4-4s-4,2-4,4"
                        />
                        <line className="st0" x1="18.83" y1="23.77" x2="18.83" y2="24.77" />
                      </g>
                    </svg>
                  </div>
                  <span>{t('needHelp')}</span>
                </div>
                <a
                  href={`${locale === 'en' ? '' : '/' + locale}/cart`}
                  className="ta-c f-g1-s1 flex-box fd-c ai-c gap-025 "
                >
                  <div
                    className="pos-rel flex-box ai-c"
                    /*style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position:  'relative' }}*/
                  >
                    <div className="cart-count"> {cartCount} </div>
                    <svg
                      alt="Cart"
                      className="header-cart-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                    >
                      <g transform="translate(0)">
                        <path className="class-a" d="M0,0H25V25H0Z" transform="translate(0)"></path>
                        <path
                          className="class-b"
                          d="M11.5,19h6.279a.5.5,0,0,1,0,1H11.5a3.5,3.5,0,0,1-3.487-3.2,3.5,3.5,0,0,1-2.306-2.912L4.694,5.318A1.5,1.5,0,0,0,3.205,4H2.5a.5.5,0,0,1,0-1h.705A2.5,2.5,0,0,1,5.687,5.2L5.907,7H21.5a.5.5,0,0,1,.488.608L20.51,14.259A3.5,3.5,0,0,1,17.094,17H9.185l-.136,0A2.5,2.5,0,0,0,11.5,19ZM6.059,8,6.7,13.776A2.5,2.5,0,0,0,9.185,16h7.908a2.5,2.5,0,0,0,2.44-1.958L20.877,8Z"
                          transform="translate(0)"
                        ></path>
                        <path
                          className="class-b"
                          d="M18,23a2,2,0,1,1,2-2A2,2,0,0,1,18,23Zm0-1a1,1,0,1,0-1-1A1,1,0,0,0,18,22Z"
                          transform="translate(0)"
                        ></path>
                        <path
                          className="class-b"
                          d="M11,23a2,2,0,1,1,2-2A2,2,0,0,1,11,23Zm0-1a1,1,0,1,0-1-1A1,1,0,0,0,11,22Z"
                          transform="translate(0)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <span>{t('cart')}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <NavigationBar locale={locale} productName={productName} deliveryText={deliveryText} />
      <div id="nav-4" className="nav-4 pos-fix bg-blue-1 sr t-05-ease">
        <NavSidebar
          // toggle={toggle}
          // pageLanguage={pageLanguage}
          locale={locale}
          isMobile={isMobile}
        />
      </div>
      <div
        id="nav-4-slideback"
        className="nav-4-slideback pos-fix"
        onClick={() => document.body.classList.remove('slide')}
      >
        &nbsp;
      </div>
      <MobileNav cartCount={cartCount} locale={locale} />
      {settingPopUp && (
        <SitePopUpBox setSettingPopUp={setSettingPopUp} applySiteSetting={applySiteSetting}/>
      )}
    </>
  )
}
