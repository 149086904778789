import basepath, { navigate } from "./basepath"
import React, { useCallback, useEffect, useState } from 'react';
import Api from '#api/Auth'
import Image from 'next/image'
import Cookies from 'js-cookie'

import { useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'
import { useSignInOverlay } from "../global-storage/overlays";
import { useCartCount } from "../global-storage/cart";
import { usePackageCategories, useProductCategories } from '../global-storage/categories'
import { useIsLoggedIn } from "../global-storage/auth";
import { useSession, signOut } from "next-auth/react"
import { loaderProp } from '#root/utils/image-utils'
import { useRouter } from 'next/router'



export default function NavSidebar({ toggle,pageLanguage,locale,isMobile}) {
  const router = useRouter()
  const { t } = useTranslation(['header','footer'])

  // global states ======================
  const {openOverlay } = useSignInOverlay(({ openOverlay }) => ({ openOverlay }))
  const {data: productCategories } = useProductCategories()
  const {data: packageCategories} = usePackageCategories()
  const {isLoggedIn, setIsLoggedIn} = useIsLoggedIn();
  // ====================================
  const userToken = Cookies.get('userToken')

  const { data: cartCount = 0 } = useCartCount(isLoggedIn ? userToken : null);

  const [activePanels, setActivePanels] = useState(new Map());
  const { data: session } = useSession()

  const toggleAccordion = useCallback((key) => {
    setActivePanels((prevActivePanels) => {
      const newActivePanels = new Map(prevActivePanels);
      newActivePanels.set(key, !newActivePanels.get(key));
      return newActivePanels;
    });
  }, [setActivePanels]);
  
  
  const [name, setName] = useState('Guest')
  const pharmacySlugs={
    id:'apotek',
    en:'pharmacy'
  }
  const pharmacySlugUrl= pharmacySlugs[locale] || pharmacySlugs['en']

    useEffect(()=>{
      if(isLoggedIn){
        const user = JSON.parse(localStorage.getItem('userDetails')).user
        setName(user.name)
      }else{
        setName('Guest')
      }
    }, [isLoggedIn])

    const logout = async() =>{
      try{
        //sign out of google session
        if(session){
          signOut({ callbackUrl: basepath(`${locale==='en'?'':"/"+locale}`) })
        }
        const id = JSON.parse(localStorage.getItem('userDetails')).user.id
        Api.logout(id)
        setIsLoggedIn(false)
      }catch(err){
        console.log(err)
        toast.warning('An error has occured',{
          position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
        })
      }
      //close sidebar
      document.body.classList.toggle("slide")
    }
    

    return (
        <>
        <div className="pos-rel flex-box fd-c fullheight">
          <div className="flex-box fullwidth ai-c">
            <a href={basepath(`${locale==='en'?'':"/"+locale}/profile`)}><img className="avatar" src={basepath("/images/avatar.svg")} alt="avatar" /></a>
            <div><strong className="hello-user">{`Hello, ${name}!`}</strong>
              <div>
                <div className="button-white"
                  onClick={() => {
                    if (isLoggedIn) {
                      logout();
                    } else {
                      document.body.classList.remove("slide")
                      openOverlay()
                    }
                  }}
                >{isLoggedIn ? "Sign out": "Sign In"}</div>
              </div>
            </div>
          </div>
          <hr />
            <div className="NavSidebar main no-scroller pt-1">
            <div className="func flex-box col2 mb-15">
        <button
          onClick={() => {
            if ((process.env.NEXT_PUBLIC_ENV == 'prod' || process.env.NEXT_PUBLIC_ENV == 'uat') && Gleap?.getInstance()) {
              Gleap.open()
            } else {
              window.open(
                `https://api.whatsapp.com/send?phone=60179722669&text=%5BB2C%5D Please help me regarding: ${window.location.href}`,
                '_blank'
              )
            }
          }}
          className="ta-c f-g1-s1 flex-box fd-c ai-c jc-sb gap-05 image-button"
        >
          <div className="pos-rel flex-box ai-c">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 50 50"
              style={{ enableBackground: 'new 0 0 50 50' }}
              xmlSpace="preserve"
              width={25}
              height={25}
            >
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    '\n            .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}\n          '
                }}
              />
              <g>
                <path
                  id="Rectangle_103_"
                  className="st0"
                  d="M19.83,31.77l-6.29,6.29c-0.63,0.63-1.71,0.18-1.71-0.71v-5.59h-8c-1.1,0-2-0.9-2-2v-26 c0-1.1,0.9-2,2-2h30c1.1,0,2,0.9,2,2v26c0,1.1-0.9,2-2,2H19.83z"
                />
                <path
                  id="Path_143_"
                  className="st0"
                  d="M23.83,35.77v4c0,1.12,0.9,2,2,2h10l5.29,5.7c0.63,0.64,1.71,0.19,1.71-0.72v-4.98h3 c1.1,0,2-0.88,2-2V22.8c0-1.12-0.9-2.03-2-2.03h-6"
                />
                <path className="st0" d="M18.83,19.77c0,0,0-2,1-3c2-2,3-3,3-5s-1-4-4-4s-4,2-4,4" />
                <line className="st0" x1="18.83" y1="23.77" x2="18.83" y2="24.77" />
              </g>
            </svg>
          </div>
          <span className="need-help">{t('needHelp')}</span>
        </button>
        <a href={basepath(`${locale==='en'?'':"/"+locale}/cart`)} className="ta-c f-g1-s1 flex-box fd-c ai-c jc-sb gap-05">
          <div className="pos-rel flex-box ai-c">
            <div className="cart-count"> {cartCount} </div>
            <svg
              className="header-cart-icon"
              xmlns="http://www.w3.org/2000/svg"
              width={25}
              height={25}
              viewBox="0 0 25 25"
            >
              <g transform="translate(0)">
                <path className="class-a" d="M0,0H25V25H0Z" transform="translate(0)" />
                <path
                  className="class-b"
                  d="M11.5,19h6.279a.5.5,0,0,1,0,1H11.5a3.5,3.5,0,0,1-3.487-3.2,3.5,3.5,0,0,1-2.306-2.912L4.694,5.318A1.5,1.5,0,0,0,3.205,4H2.5a.5.5,0,0,1,0-1h.705A2.5,2.5,0,0,1,5.687,5.2L5.907,7H21.5a.5.5,0,0,1,.488.608L20.51,14.259A3.5,3.5,0,0,1,17.094,17H9.185l-.136,0A2.5,2.5,0,0,0,11.5,19ZM6.059,8,6.7,13.776A2.5,2.5,0,0,0,9.185,16h7.908a2.5,2.5,0,0,0,2.44-1.958L20.877,8Z"
                  transform="translate(0)"
                />
                <path
                  className="class-b"
                  d="M18,23a2,2,0,1,1,2-2A2,2,0,0,1,18,23Zm0-1a1,1,0,1,0-1-1A1,1,0,0,0,18,22Z"
                  transform="translate(0)"
                />
                <path
                  className="class-b"
                  d="M11,23a2,2,0,1,1,2-2A2,2,0,0,1,11,23Zm0-1a1,1,0,1,0-1-1A1,1,0,0,0,11,22Z"
                  transform="translate(0)"
                />
              </g>
            </svg>
          </div>
          <span>Cart</span>
        </a>
                </div>
                <hr />
                <a className="to-home" href={basepath(`${locale==='en'?'':"/"+locale}`)}>{t('home')}</a>
                <div className="accordion-container-side">
                    <div className={`ac ${activePanels.get('pharma') ? 'is-active' : ''}`}>
                        <div className="ac-header">
                            <button
                                className="ac-trigger"
                                onClick={() => toggleAccordion('pharma')}>
                                    {t('onlinePharmacy')}
                                    <span>{t('new')}</span>
                            </button>
                        </div>
                        {activePanels.get('pharma') 
                        && (<div className="ac-panel">
                            <ul className="ls-n">
                                <li><a href={basepath(`${locale==='en'?'':"/"+locale}/${pharmacySlugUrl}`)}><span>Home</span></a></li>
                                {productCategories.map((category, index) => (
                                    <li key={index}>
                                        <a href={`${locale==='en'?'':"/"+locale}/${pharmacySlugUrl}/${category?.language[locale.split('-')[0]]?.url_slug||category?.language['en']?.url_slug}`}>
                                            <span>{category.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>)}
                    </div>
                     <div className={`ac ${activePanels.get('health') ? 'is-active' : ''}`}>
                        <div className="ac-header">
                            <button
                                className={`ac-trigger ${activePanels.get('health') ? 'active' : ''}`}
                                onClick={() => toggleAccordion('health')}>
                                    {t('healthServices')}
                                    <span>{t('new')}</span>
                            </button>
                        </div>
                        {activePanels.get('health') 
                        && (<div className="ac-panel" style={{transitionDuration:500}}>
                            <ul className="ls-n">
                                <li><a href={basepath(`${locale==='en'?'':"/"+locale}/health-services`)}><span>Home</span></a></li>
                                {packageCategories.map((category, index) => (
                                    <li key={index}>
                                        <a href={basepath(`${locale==='en'?'':"/"+locale}/health-services/categories/${category.url_slug}`)}>
                                            <span>{category.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        )}
                    </div> 
                </div>
                <hr />
                { isLoggedIn ? (
                  <>
                    <div><a className="button-blue" href={basepath(`${locale==='en'?'':"/"+locale}/profile/orders`)}>{t('ordersTracking')}</a></div>
                    <div><a className="button-blue" href={basepath(`${locale==='en'?'':"/"+locale}/profile/health-services`)}>{t('healthServicesHistory')}</a></div>
                  </>
                ) : (
                  <>
                    <div><a className="button-blue" href={basepath(`${locale==='en'?'':"/"+locale}/about-us`)}>{t('aboutUs', { ns: 'footer' })}</a></div>
                    <div><a className="button-blue" href={basepath(`${locale==='en'?'':"/"+locale}/corporate-partners`)}>{t('corporatePartners', { ns: 'footer' })}</a></div>
                    <div><a className="button-blue" href={basepath(`${locale==='en'?'':"/"+locale}/dispensation-policy`)}>{t('dispensation', { ns: 'footer' })}</a></div>
                    <div><a className="button-blue" href={basepath(`${locale==='en'?'':"/"+locale}/privacy-policy`)}>{t('privacyPolicy', { ns: 'footer' })}</a></div>
                    <div><a className="button-blue" href={basepath(`${locale==='en'?'':"/"+locale}/term-of-use`)}>{t('TermUse', { ns: 'footer' })}</a></div> 
                  </>
                )}

                {/* <div><a className="button-blue" href="https://www.doctoroncall.com.my/help">{t('faq')}</a></div>
                <div><a className="button-blue" href="https://www.doctoroncall.com.my/media">{t('media')}</a></div>
                <div><a className="button-blue" href="https://www.doctoroncall.com.my/careers">{t('careers')}</a></div>
                <div><a className="button-blue" href="https://www.doctoroncall.com.my/online-doctor">{t('panelDoctors')}</a></div>
                                <div><a className="button-blue" href="https://www.doctoroncall.com.my/contact-us">{t('contactUs')}</a></div> */}
                {/* <div className="mt-1">
                  <h3 className="ta-c mb-025">{t('language')}</h3>
                  <div className="mb-1 ta-c">
                    <button  style={{ backgroundColor: 'transparent', color:'white', boxShadow:'none',border:'none' ,height:'min-content',fontSize:'0.8em',fontWeight:pageLanguage=='id'?"800":"500",textDecoration:pageLanguage=='id'?"underline":""}} onClick={() => toggle('id')}>
                    Bahasa Indonesia
                    </button>  
                    | 
                    <button  style={{ backgroundColor: 'transparent', color:'white', boxShadow:'none',border:'none' ,height:'min-content',fontSize:'0.8em',fontWeight:pageLanguage=='en'?"800":"500",textDecoration:pageLanguage=='en'?"underline":"" }} onClick={() => toggle('id')}>
                    English
                    </button>  
                  </div>  
                </div> */}
            </div>
            


            <div className="follow-us mt-2">   
            <h3 className="ta-c">{t('followUs')}</h3>
            <div className="socmed flex-box col5 centralized fullwidth">
              <a className="f-g1-s0" href="https://www.facebook.com/doctoroncallMY" target="_blank">
                <Image
                  blurDataURL={'/images/socmed-fb.svg'}
                  src={'/images/socmed-fb.svg'}
                  alt={"fb-logo"}
                  width={27.5}
                  height={27.5}
                  loader={loaderProp}
                /> 
              </a>
              <a className="f-g1-s0" href="https://www.instagram.com/doctoroncallmy/" target="_blank">
                <Image
                  blurDataURL={'/images/socmed-ig.svg'}
                  src={'/images/socmed-ig.svg'}
                  alt={"ig-logo"}
                  width={27.5}
                  height={27.5}
                  loader={loaderProp}
                /> 
              </a>
              <a className="f-g1-s0" href="https://twitter.com/doctoroncallmy" target="_blank">
                <Image
                  blurDataURL={'/images/socmed-tw.svg'}
                  src={'/images/socmed-tw.svg'}
                  alt={"tw-logo"}
                  width={27.5}
                  height={27.5}
                  loader={loaderProp}
                /> 
              </a>
              <a className="f-g1-s0" href="https://www.youtube.com/c/DoctorOnCallMalaysia" target="_blank">
                <Image
                  blurDataURL={'/images/socmed-yt.svg'}
                  src={'/images/socmed-yt.svg'}
                  alt={"yt-logo"}
                  width={27.5}
                  height={27.5}
                  loader={loaderProp}
                /> 
              </a>
              <a className="f-g1-s0" href="https://www.linkedin.com/company/doctoroncall.com.my" target="_blank">
                <Image
                  blurDataURL={'/images/socmed-in.svg'}
                  src={'/images/socmed-in.svg'}
                  alt={"in-logo"}
                  width={27.5}
                  height={27.5}
                  loader={loaderProp}
                /> 
              </a>
            </div>
            </div>
            </div>
        </>
    );
}