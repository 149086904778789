// this file is used to create a store for the cart using react query

import { useQuery } from 'react-query'
import Api from '../api/Market'

export const useCartCount = (token) => {
  return useQuery({
    queryKey: 'cartCount',
    queryFn: () => Api.getCartCount({ token }),
    select: (data) => data?.count,
    refetchOnWindowFocus: false,
    enabled: !!token, 
  })
}