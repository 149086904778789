import basepath from '#components/basepath'
import { useState, useEffect, Fragment } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { usePackageCategories, useProductCategories } from '../global-storage/categories'

export default function MarketNav({locale,productName, deliveryText}) {
  const { t } = useTranslation('header')
  const router = useRouter();
  const [slugs,setSlugs]=useState([])
  const pharmacySlugs={
    id:'apotek',
    en:'pharmacy'
  }
  const pharmacySlugUrl= pharmacySlugs[locale] || pharmacySlugs['en']

  const {data: productCategories } = useProductCategories()
  const {data: packageCategories} = usePackageCategories()


  useEffect(()=>{
    const url = router.asPath;

    // Step 1: Split the URL into an array using the '/' character as a separator
    const urlArray = url.split('/').filter(item => item !== '');

    // Step 2: Loop through the array and combine current value with previous values
    const combinedArray = [];
    for (let i = 0; i < urlArray.length; i++) {
      if (i === 0) {
        combinedArray.push(urlArray[i]); // First element remains as is
      } else {
        const combinedValue = combinedArray[i - 1] + '/' + urlArray[i];
        combinedArray.push(combinedValue);
      }
    }
    setSlugs([combinedArray,urlArray])
  },[])
  let checkLocalIndex= locale=='en'?0:1

  return (
    <div className="nav-3 br-b-gray-1 slide-disable sr t-05-ease">
      <div className="header-nav r-1023 flex-box">
        <div className="f-g0-s0">
          <a className="button-blue" href={(locale==='en'?'':"/"+locale)+'/'+pharmacySlugUrl}>
            {t('onlinePharmacy')}<span>{t('new')}</span>
            <div className="tab"></div>
          </a>
          <div className="nav-3-d br-b-gray-1 bg-gray-2 slide-disable sr t-05-ease pt-05 pb-05 pos-abs fullwidth">
            <div className="wrap-2">
              <div className="flex-box gap-2 desc">
                {/* <div className="pad-05 fb-40">
                  <div className="flex-box br-b-blue-1">
                    <h2 className="f-g1-s1" style={{width:'max-content'}} >{t('trendingCategories')}</h2>
                    Commented out because all categories view is not implemented yet
                    <a className="see-all" href="/pharma-category">
                      <span>SEE ALL</span> <img src={basepath('/images/go.svg')} alt />
                    </a>{' '}
                  </div>
                  <ul className="ls-n flex-box gap-05 fw fd-c">
                    {featuredProductCategories.map((category, index) => (
                      <li key={`featured-${index}`}>
                        <a href={(locale==='en'?'':"/"+locale)+`/${pharmacy}/${category?.url_slug}`} target="_blank">
                          <span>{category?.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div> */}
                <div className="pad-05 f-g1-s0">
                  <div className="flex-box br-b-blue-1">
                    <h2 className="f-g1-s1">{t('exploreBy')}</h2>
                    {/* Commented out because all categories view is not implemented yet */}
                    {/* <a className="see-all" href="/pharma-category">
                      <span>SEE ALL</span> <img src={basepath('/images/go.svg')} alt />
                    </a>{' '} */}
                  </div>

                  <ul className="ls-n grid-box gap-05 col4 fw">
                    {productCategories?.map((category, index) => (

                      <li key={`category-${index}`}>
                        <a href={`${locale==='en'?'':"/"+locale}/${pharmacySlugUrl}/${category?.language[locale]?.url_slug||category?.language['en']?.url_slug}`} target="_self">
                          <span>{category?.language[locale.split('-')[0]]?.name || category?.language['en']?.name }</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-g1-s0">
          <a className="button-blue" href={`${locale==='en'?'':"/"+locale}/health-services`}>
          {t('healthServices')}<span>{t('new')}</span>
            <div className="tab"></div>
          </a>
          <div className="nav-3-d br-b-gray-1 bg-gray-2 slide-disable sr t-05-ease pt-05 pb-05 pos-abs fullwidth">
            <div className="wrap-2">
              <div className="flex-box gap-2 desc">
                <div className="pad-05 f-g1-s0">
                  <div className="flex-box br-b-blue-1">
                    <h2 className="f-g1-s1">{t('categories')}</h2>
                    <a className="see-all" href={basepath('/health-services')}>
                      <span>SEE ALL</span> <img src={basepath('/images/go.svg')} alt='go'/>
                    </a>{' '}
                  </div>

                  <ul className="ls-n grid-box gap-05 col4 fw">
                    {packageCategories?.map((category, index) => (

                      <li key={index}>
                        <a href={`${locale==='en'?'':"/"+locale}/health-services/categories/${category.url_slug}`} target="_self">
                          <span>{category.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-g0-s0">
          {Object.keys(deliveryText).length !== 0 && (
            <div className="delivery-location flex-box ai-c pl-3 pr-1 fullheight">
              <div className='fs-1 ta-l'>
                <span className="fs-08">Deliver to</span><br />
                {deliveryText.location}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="breadcrumbs flex-box bg-blue-1 pt-03 pb-025 ai-c">

          <a className="pl-0" href={`${locale=='en'?"/":'/'+locale}`}>
            <img src={basepath('/images/home.svg')} className="home" alt="home" />
          </a>
          {/* {  slugs[0]?.length>=4 && slugs[0].includes(`${locale=='en'?"":locale+'/'}health-services`) 
          // && !slugs[0].includes(`${locale=='en'?"":locale+'/'}health-services/stores`) 
          && !slugs[0][slugs[0].length-1].includes('page') 
          && !slugs[0][slugs[0].length-1].includes('collection') ?
            <>
              {
                
                slugs[0]?.map((slug,i)=>{
                  if(!i==0){
                    let x=slug
                    let text=slugs[1][i]


                    // x=(slugs[0][i].includes('health-services/')) && (i!==slugs[0].length-1)? 
                    //   slugs[0][i].replace(/health-services\/[^/]+/, 'health-services')
                    //   :
                    //   x

                    // text=(slugs[0][i].includes('health-services/')) && (i==slugs[0].length-3) && checkLocalIndex? 
                    //   'skip'
                    //   :
                    //   text
                      
                      if(text!=='skip'){
                        return(
                          <Fragment key={i}>
                            <span>
                              <img src={basepath('/images/next-white.svg')} alt="next" />
                            </span>
                            <a href={`/${x}`}>{
                            (productName && i==((slugs[1].length)-1))?productName 
                            :((!checkLocalIndex&&i==1?slugs[1][0]:text).replaceAll('-',' '))
                            }</a> 
                          </Fragment>
                        )
                      }
                  }
                })
              }
            </>
            : */}
            <>
              {            
                slugs[0]?.map((slug,i)=>{
                  if(!i==0 || (!slugs.includes('collection')&&!checkLocalIndex)){
                    let urlSlug= slug
                    //replace text 'checkout' to 'cart' in breadcrumbs 
                    slugs[1][i]= (slug.includes('checkout') && !slug.includes('checkout/'))? 'cart':slugs[1][i]
                    //replace text in '/{uuid}' to 'checkout' in breadcrumbs
                    slugs[1][i]= (slug.includes('checkout/'))? 'checkout':slugs[1][i]
                    //replace url 'checkout' to 'cart' in breadcrumbs
                    urlSlug= (slug.includes('checkout') && !slug.includes('checkout/'))?`${locale=='en'?"":locale+'/'}cart`:urlSlug

                    // remove these slugs from breadcrumbs
                    urlSlug= slug.includes('confirmation')?'/':urlSlug
                    urlSlug= (slug.includes('page') && !slug.includes('page/'))? '/':urlSlug
                    //replace page number text with "page" next to it
                    urlSlug=!isNaN(slugs[1][i])? slugs[1][i]='page '+slugs[1][i]:urlSlug

                    let slugCleanText=(slugs[1][i]?.replaceAll('-',' ')?.split('?')[0]) // remove query params from slug & replace '-' with space

                    let breadcrumb=(productName && i==((slugs[1].length)-1))?
                      productName //replace last slug with product name if available
                      :
                      (slugCleanText)

                    //ignore / slugs replaced above && remove 'collection' from breadcrumbs 
                    if(urlSlug!=='/' && slugs[1][i]!=='collection'){
                      return(
                        <Fragment key={i}>
                          <span>
                            <img src={basepath('/images/next-white.svg')} alt="next" />
                          </span>
                          <a href={basepath(`/${urlSlug}`)}>{breadcrumb}</a> 
                        </Fragment>
                      )
                    }
                  }
                })
              }
            </>
          {/* } */}
        </div>  
    </div>
    
  )
}
