import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import TopBar from '#components/TopBar'
import Header from '#components/Header'
import Footer from '#components/Footer'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'next-i18next'
import { localeMapper } from '#root/utils/url-utils'
import { hreflangGenerator } from '#root/utils/hreflang-utils'
import Head from 'next/head'
import CountryPopup from '#components/CountryPopup'



const availableCountries = [
  { code: 'sg', name: 'Singapore', short: 'SG', url: 'sg', currency:'SGD' },
  { code: 'id', name: 'Indonesia', short: 'ID', url: 'id', currency:'IDR' },
  { code: 'en', name: 'International', short: 'INT.', url: '', currency: 'MYR' }
]

function MainLayout(props) {
  const { Component, pageProps } = props

  const route = useRouter()
  let { i18nLocale, locale } = localeMapper(route.locale)
  let windowSize=null

  let hreflang = hreflangGenerator({
    countriesLocale: route.locales,
    currentLocale: locale,
    pageProps: props.pageProps,
    fullUrl: props.pageProps.currentFullUrl,
    slugArray: props.pageProps.slugArray
  })

  if (typeof window !== 'undefined') {
    // detect window screen width function
    windowSize=window.innerWidth
  }
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(i18nLocale)
  }, [i18nLocale])

  const canonical = useMemo(() => {
    return (
      process.env.NEXT_PUBLIC_APP_URL +
      route.asPath
    )
  }, [route, locale])

  const [isMobile, setIsMobile] = useState(null)
  const [noScroll, setNoScroll] = useState(false)

  useEffect(() => {
    if (noScroll) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [noScroll])

  const toggleNoScroll = (value) => {
    setNoScroll(value);
  }

  useEffect(() => {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };

    if (isMobile.any()) {
      document.body.setAttribute('id', 'body')
      document.body.classList.add('mobile');
    } else {
      document.body.setAttribute('id', 'body')
      document.body.classList.add('desktop');
    }
    setIsMobile(isMobile.any())

    if(route.asPath.includes('/cart')) {
      document.body.classList.add('cart');
    }
    isMobile={...isMobile, windowSize}
    setIsMobile(isMobile)

  }, [])

  const getLayout = Component.getLayout || ((page) => page)
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <title>
          {pageProps?.pageHeader?.title ? pageProps?.pageHeader?.title : 'DoctorOnCall'}
        </title>
        x
        <meta
          name="description"
          content={
            pageProps?.pageHeader?.description ? pageProps?.pageHeader?.description : 'DoctorOnCall'
          }
        />
        <meta property="og:locale" content={i18nLocale} />
        <meta
          property="og:title"
          content={pageProps?.pageHeader?.title ? pageProps?.pageHeader?.title : 'DoctorOnCall'}
        />
        <meta
          property="og:description"
          content={
            pageProps?.pageHeader?.description ? pageProps?.pageHeader?.description : 'DoctorOnCall'
          }
        />
        <meta property="og:url" content={process.env.NEXT_PUBLIC_APP_URL + route.asPath} />
        <meta property="og:site_name" content={'doctoroncall.com'} />
        {pageProps?.pageHeader?.image && (
          <>
            <meta property="og:image" content={pageProps?.pageHeader?.image} />
            <meta property="og:image:width" content={300} />
            <meta property="og:image:height" content={300} />
            <meta property="og:image:type" content="image/jpeg" />
          </>
        )}
        <link rel="canonical" href={canonical} />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        {/* favicon */}
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href={`${process.env.NEXT_PUBLIC_BASEPATH || ''}/img/favicon.ico`}
        />
        {/* hreflang from page props */}
        {hreflang?.map((item, index) => {
          return <>
            {item.hreflang==='en' && <link key={'hreflang_xdefault'} rel="alternate" hreflang='x-default' href={process.env.NEXT_PUBLIC_ENV == 'local' ? "http://"+item.href : "https://"+item.href} />}
            <link key={item.hreflang+index} rel="alternate" hreflang={item.hreflang} href={process.env.NEXT_PUBLIC_ENV == 'local' ? "http://"+item.href : "https://"+item.href} />
          </>
        })}
        {/* hrefpage from page props */}
        {pageProps?.hrefPage ? (
          pageProps?.hrefPage?.map((item, index) => {
            return <link key={'pageprop_'+index} rel={item.rel} href={process.env.NEXT_PUBLIC_ENV == 'local' ? "http://"+item.href : "http://"+item.href} />
          })
        ) : null}

        <link
          rel="stylesheet"
          href={`${process.env.NEXT_PUBLIC_BASEPATH || ''}/css/stylesheet.css`}
        />

        {process.env.NEXT_PUBLIC_APP_GTM_ENABLED == 'true' ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_APP_GTM_TAG_CODE}');`
            }}
          />
        ) : null}
        <script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="text/javascript" async="async"></script>
      </Head>
      <TopBar />
      <Header
        locale={locale}
        productName={pageProps?.productName}
        isMobile={isMobile}
        urlTranslate={hreflang}
        yourOnlineDoctorPharmacyIsH2={pageProps?.yourOnlineDoctorPharmacyIsH2 || false}
      />
      {getLayout(
        <Component {...pageProps} availableCountries={availableCountries} isMobile={isMobile} toggleNoScroll={toggleNoScroll} />
      )}
      <Footer availableCountries={availableCountries} locale={locale} />
      <CountryPopup locale={locale} availableCountries={availableCountries}/>
      <ToastContainer toastStyle={{ zIndex: '90000' }} />
    </>
  )
}

export default MainLayout
